@import "styles/theme.scss";
.label {
  --bottom-color: var(--grey-light-color);
  --shadow-color: none;

  @include label;

  &Error {
    --bottom-color: var(--wrong-color);
    --shadow-color: var(--wrong-color);
  }

  &Valid {
    --bottom-color: var(--default-right-color);
    --shadow-color: none;
  }
}

.option {
  font-family: $font-family;
  font-size: $font-size-xs;
}

.labelTitle {
  @include label-title;

  &Disabled {
    color: rgba($primary-color, 0.6);
  }
}

.errorMessage {
  @include error-message;
}

.errorMessageShow {
  @include message-show-animation;
}
