@import "styles/theme.scss";
.authorization {
  display: grid;
  width: 100%;
  align-items: center;
  padding-block: 28px;
  overflow-y: auto;
}

.title {
  margin-bottom: 50px;
  font-size: $font-size-l;
  color: $accent-color;
  font-weight: bold;
  text-align: center;
}

.input + .input {
  margin-top: 50px;
}

.button {
  margin-top: 46px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 264px;
  margin: 0 auto;
}

.options {
  margin-top: 24px;
  align-self: flex-end;
  font-size: $font-size-xxs;
}
