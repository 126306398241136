@import "styles/theme.scss";
.container {
  position: relative;
  min-width: 422px;
  display: grid;
  place-items: center;
  background: var(--main-gradient);
  isolation: isolate;
  overflow: hidden;

  @media (max-height: 620px) {
    overflow-y: auto;

    &::before {
      content: revert;
    }
  }

  &::before {
    --dimension: calc(100% + 138px);

    content: '';
    position: relative;
    grid-area: 1/1;
    border-radius: 50%;
    border: 1px solid #fff;
    z-index: -1;

    @supports (aspect-ratio: 1/1) {
      width: var(--dimension);
      aspect-ratio: 1/1;
    }
  }
}

.wrapper {
  grid-area: 1/1;
}

.logo {
  max-width: 200px;
  margin: 0 auto;
}

.logo + .actionGroup {
  margin-top: 208px;
}

.title {
  margin-bottom: 48px;
  text-transform: uppercase;
  text-align: center;
  color: $grey-light-color;
  font-weight: $font-weight-bold;
}
