@import "styles/theme.scss";
.datePicker input,
.datePicker input:focus-visible,
.datePicker input:focus {
  padding: 16px;
  background: none;
  width: 100%;
  font-size: $font-size-xs;
  box-sizing: border-box;
}

.datePicker > div {
  width: 100%;
  height: 100%;
  border: unset;
  font-weight: $font-weight-light;
  font-size: inherit;
  font-family: inherit;
}

.datePicker.datePicker {
  background-color: $light-blue-color;
  border-radius: $input-radius;
  border-bottom: 2px solid $secondary-color;
  height: 52px;
  width: 100%;
}

.disabled.disabled {
  opacity: 0.6;
}

.datePicker > div > fieldset {
  border: unset;
}

.label {
  @include label;
}

.container {
  @include input;
}

.containerError.containerError {
  @include input-error;
}

.labelTitle {
  @include label-title;
}

.errorMessage {
  @include error-message;
}

.errorMessageShow {
  @include message-show-animation;
}

.valid.valid {
  @include input-valid;
}
