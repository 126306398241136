@import "styles/theme.scss";
.button {
  @include button;

  &SizeS {
    @include button-small;
  }

  &Text {
    flex-grow: 1;
  }
}

.outlined {
  @include button-outlined;
}
