@import "styles/theme.scss";
.label {
  @include label;
}

.wrapper {
  position: relative;
  display: grid;
  align-items: center;
}

.input {
  appearance: none;
  position: relative;
  grid-area: 1/1;
  width: 100%;
  height: 52px;
  padding: 16px;
  border: unset;
  border-bottom: 2px solid $grey-light-color;
  border-radius: $input-radius;
  background-color: $light-blue-color;
  font-size: $font-size-xs;
  color: $grey-light-color;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: box-shadow 0.4s, border-color 0.4s;

  &:read-only {
    color: rgba($grey-light-color, 0.5);
    border-bottom-color: rgba($grey-light-color, 0.5);
    box-shadow: none;
    opacity: 0.6;
  }

  &::placeholder {
    color: $grey-light-color;
  }
}

.inverted {
  background-color: #fff;
}

.isError {
  @include input-error;
}

.labelTitle {
  @include label-title;
}

.errorMessage {
  @include error-message;
}

.errorMessageShow {
  @include message-show-animation;
}

.valid {
  @include input-valid;
}

%icon-padding {
  padding: 16px;
}

.isIcon {
  padding-right: 40px;
}

.icon {
  position: relative;
  grid-area: 1/1;

  &Right {
    margin-left: auto;
  }
}

.buttonIcon {
  @extend %icon-padding;

  background: rgb(0 0 0 / 0%);
}

.iconPadding {
  @extend %icon-padding;
}
