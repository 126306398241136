@import "styles/theme.scss";
.container {
  width: 100%;
  padding: 28px 32px;
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(264px, 1fr));
  column-gap: 10px;
  grid-template-areas:
    'firstName secondName'
    'email email'
    'birthDate position'
    'subdivision location'
    'password confirmPassword'
    'termsOfService termsOfService'
    'privacyPolicy privacyPolicy'
    'submitButton submitButton';
}

.email {
  grid-area: email;
}

.title {
  text-align: center;
  color: $accent-color;
  margin-bottom: 24px;
  margin-top: auto;
}

.input {
  min-height: 90px;
}

.form {
  margin-bottom: auto;
}

.button {
  grid-area: submitButton;
  place-self: center;
  margin-top: 24px;
}

.checkboxWrapper {
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  margin-top: 18px;
  font-size: $font-size-xxs;

  .link {
    color: var(--primary-accent-color);
    text-decoration: underline;
  }
}

.checkboxWrapper + .checkboxWrapper {
  margin-top: 10px;
}

.checkbox {
  appearance: none;
  margin: 0;
  display: grid;
  place-content: center;
  border: 1px solid $grey-light-color;
  border-radius: 2px;
  background-color: $light-blue-color;
  width: 16px;
  height: 16px;

  &:checked {
    border-color: transparent;
    background-color: var(--primary-accent-color);
  }

  &:checked::before {
    --borderWidth: 2px;

    content: '';
    margin-bottom: 3px;
    width: 3px;
    height: 7px;
    transform: rotate(45deg);
    border-bottom: var(--borderWidth) solid #fff;
    border-right: var(--borderWidth) solid #fff;
  }
}

.checkboxError {
  border-color: $wrong-color;
}

.checkboxLabel {
  color: $secondary-color;
  margin-left: 10px;
  white-space: pre;
}
